import { useState } from "react";
import { useUpdateUserNameMutation } from "services/graphql";

interface UpdateNameInput {
  name: string;
}

interface UpdateNameResult {
  isUpdating: boolean;
  error: Error | null;
  success: { message: string } | null;
  updateName: (input: UpdateNameInput) => Promise<void>;
}

export function useUserUpdateName(): UpdateNameResult {
  const [error, setError] = useState<Error | null>(null);
  const [success, setSuccess] = useState<{ message: string } | null>(null);
  const [updateNameMutation, { loading }] = useUpdateUserNameMutation();

  const updateName = async ({ name }: UpdateNameInput) => {
    try {
      await updateNameMutation({
        variables: {
          input: {
            name,
          },
        },
      });
      setError(null);
      setSuccess({ message: "Successfully updated name." });
    } catch (err) {
      if (err instanceof Error) {
        setError(err);
      } else {
        setError(new Error("An unknown error occurred"));
      }
      setSuccess(null);
    }
  };

  return {
    isUpdating: loading,
    error,
    success,
    updateName,
  };
}
